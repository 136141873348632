import { BrowserRouter } from "react-router-dom";
import Appbar from '../components/Appbar';
import AnimatedRoutes from "./AnimatedRoutes";

const AppRoutes = () => {

  return (
    <BrowserRouter>
        <Appbar/>
          <AnimatedRoutes/>
    </BrowserRouter>
  )
}

export default AppRoutes