import React, { useState, useRef, useEffect, lazy, Suspense } from 'react'
import '../css/Contact.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import contactImage from '../assets/Desktop/Contact/Image_contact1.png'
import contactImage2 from '../assets/Desktop/Contact/image_contact2.png'
import contactImage3 from '../assets/Mobile/Contact/Image_contact1_mobile.png'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { MuiTelInput } from 'mui-tel-input'
import emailjs from '@emailjs/browser';
import Footer from './Footer';

const FloatingContactCard = lazy(() => import('./FloatingContactCard'));

const styleInputs = {
  marginTop: '0.5rem',
  borderRadius: '25px',
  width: '100%',  
  boxShadow: '0px 3px 10px #0000001A', 
  '& label.Mui-focused': {
    color: '#AE3ADB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '25px',
      borderColor: '#FFFFFF',
      width: 'auto', 
      boxShadow: '0px 3px 10px #0000001A', 
    },
    '&:hover fieldset': {
      borderColor: '#AE3ADB',
    },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#AE3ADB',
    // },
  },
  '@media (max-width: 1280px)': {
    marginTop: '0px',
  },
  '@media (max-width: 765px)': {
    width: '95%',
  },
  '@media (max-width: 480px)': {
    width: '90%',
  }
};

const Contact = () => {

  const form = useRef();
  const [t] = useTranslation("global")
  const [sendContact, setSendContact] = useState(false);
  const [formData, setFormData] = useState({name: "", last_name: "", email: "", comment: ""});
  const [formError, setFormError] = useState(false);
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = React.useState(true);

  // Simulando una carga de 2 segundos
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("entre");
    
    if(formData.name?.length >= 2 && formData.last_name?.length >= 2 && phone?.length >= 2 && formData.email?.length >= 2 && formData.comment?.length >= 2){
      setSendContact(true);
      emailjs.sendForm('service_2eeosu6', 'template_i30ij5r', form.current, '6wS7VxgoO36ViQvcC')
        .then((result) => {
            console.log(result.text);
            console.log('enviado')
        }, (error) => {
            console.log(error.text);
        });
    }else{
      setFormError(true);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    setFormData({...formData, [name]: value});
  };


  return (
    <> 
    <div className='BoxContact'>

      <motion.div 
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
      className='BoxForm' 
      style={{ width: '100%' }}>
        <div className='textContent'>
          <h2 className="thanksTitle">{sendContact ? 
          t("Contact.Thanks") 
          : 
          t("Body.WorkingButton2")}</h2>
          <div className='bodyText'>
            {sendContact ? 
            <p>
              <strong>{t("Contact.ThanksText")}</strong> <br></br>{t("Contact.ThanksText2")}
              <Link to='/'>
                  <p style={{ font: 'normal normal 18px/50px cream DEMO', maxWidth: 450, marginTop: -5, color: '#7F00B2', textDecoration: 'none', ':hover': { color: 'red' } }}>
                    {t("Contact.ThanksButtom")}
                </p>
              </Link>
            </p>
            : 
            <p><strong>{t("Contact.ContactText")}</strong> <br></br>{t("Contact.ContactText2")}</p>}
          </div>
        </div>
          {!sendContact && 
            <form 
            ref={form}
            className='form-contact' 
            onSubmit={handleSubmit}>
                  <TextField
                    sx={styleInputs} 
                    color={formData.name?.length >= 2 && 'success'}
                    id="name"
                    name="name"
                    required
                    error={formError && !formData.name}
                    helperText={formError && !formData.name ? t("Contact.Required") : ''}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formData.name?.length >= 2 && <CheckCircleOutlineIcon sx={{ color:'#18810C' }}/>}
                        </InputAdornment>
                      ),
                    }}
                    label={t("Contact.FirstName")}
                  />
                  <TextField
                    sx={styleInputs} 
                    color={formData.last_name?.length >= 2 && 'success'}
                    id="last_name"
                    name="last_name"
                    required
                    error={formError && !formData.lastname}
                    helperText={formError && !formData.lastname ? t("Contact.Required") : ''}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formData.last_name?.length >= 2 && <CheckCircleOutlineIcon sx={{ color:'#18810C' }}/>}
                        </InputAdornment>
                      ),
                    }}
                    label={t("Contact.LastName")}
                  />

                  <MuiTelInput 
                  name='phone_number' 
                  id='phone_number' 
                  sx={styleInputs} 
                  color={phone?.length >= 8 && 'success'}
                  value={phone} 
                  onChange={handleChange} 
                  placeholder={t("Contact.Phone")} 
                  helperText={formError && !phone ? t("Contact.Required") : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {phone?.length >= 8 && <CheckCircleOutlineIcon sx={{ color:'#18810C' }}/>}
                      </InputAdornment>
                    ),
                  }}
                  required/>

                  <TextField
                    sx={styleInputs}
                    color={formData.email?.includes('@') & formData.email?.length >= 5 && 'success'}
                    id="email"
                    name="email"
                    inputProps={{ inputMode: 'email', pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$' }}
                    required
                    error={formError && !formData.email}
                    helperText={formError && !formData.email ? t("Contact.Required") : ''}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formData.email?.includes('@') && <CheckCircleOutlineIcon sx={{ color:'#18810C' }}/>}
                        </InputAdornment>
                      ),
                    }}
                    label={t("Contact.Email")}
                  />
                  <TextField
                    sx={styleInputs}
                    color={formData.comment?.length >= 10 && 'success'}
                    id="comment"
                    multiline
                    rows={4}
                    name="comment"
                    required
                    error={formError && !formData.comment}
                    helperText={formError && !formData.comment ? t("Contact.Required") : ''}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formData.comment?.length >= 10 && <CheckCircleOutlineIcon sx={{ color:'#18810C' }}/>}
                        </InputAdornment>
                      ),
                    }}
                    label={t("Contact.MessagePlaceholder")}
                    InputLabelProps={{ style: { fontSize: '14px' } }}
                  />
                  <Button sx={{maxWidth: 100, minWidth: 90, marginTop: 2, borderRadius: 33, background: '#AE3ADB', 
                  '&:hover': {backgroundColor: '#7F00B2'},
                  '@media (max-width: 1280px)': {
                    marginTop: 0,
                    marginBottom: 5
                  }
                  }} variant="contained" type="submit">
                    {t("Contact.ContactBottom")}
                  </Button>
              </form>
            }
          </motion.div>

        <div className='BoxImg'>
          
            <motion.img 
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
              className='imageContact' 
              loading='lazy' 
              src={sendContact ? contactImage2 : contactImage} 
              alt="Contact Us Image" />

              <motion.img  
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
              exit={{ opacity: 0, x: 100 }}
              className='imageContact2' 
              loading='lazy' 
              src={sendContact ? contactImage2 : contactImage3} 
              alt="Contact Us Image" />
        </div>
      </div>

      <div>
        
      {!loading && (
        <Suspense>
          <FloatingContactCard />
          <Footer/>
        </Suspense>
      )}
    </div>
  </>
  )
}

export default Contact