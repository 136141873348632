import React, { useState } from 'react';
import AppRoutes from './routes/AppRoutes';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next';
import english from './translations/en/global.json'
import spanish from './translations/es/global.json'

function App() {

  const [languages, setLanguages] = useState("en")

  i18next.init({
    interpolation: { escapeValue: false },
    lng: languages, // Idioma con el cual inicia
    resources: {
        es: {
          global: spanish
        },
        en: {
          global: english
        }
    }
  })

  return (
    <I18nextProvider i18n={i18next}>
      <AppRoutes/>
    </I18nextProvider>
  );
}

export default App;
