import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FloatingCard from './FloatingCard';
import ContactCard from './ContactCard';
import ProjectsCard from './ProjectsCard';
import SimpleCard from './SimpleCard';
import Carousel from './Carousel';
import { motion } from 'framer-motion';
import Footer from './Footer';

const Home = () => {
  return (
    <>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      {/* <CssBaseline /> */}
      <Carousel/>
      <Box sx={{ height: '100%', marginTop: -20, '@media (max-width: 768px)': {
            marginTop: -30,}}}>
        <FloatingCard/>
        <div style={{maxWidth: '100%', overflow: 'hidden', paddingLeft: '10%',paddingRight: '10%', borderRadius: '12px'}}>
            <ContactCard/><ProjectsCard/>
        </div>
        <SimpleCard/>
      </Box>
    </motion.div>
    <Footer/>
    </>
  )
}

export default Home