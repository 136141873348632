import React, { useRef } from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import '../css/Cards.css'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// Images
import innovationImage from '../assets/Desktop/Home/Icono_Innovador.png'
import desarrolloImage from '../assets/Desktop/Home/Icono_Desarollo.png'
import automatizacionImage from '../assets/Desktop/Home/Icono_Automatizacion.png'
import uxImage from '../assets/Desktop/Home/Icono_UX.png'
import simpleImage from '../assets/Desktop/Home/Icono_Simple.png'

export default function FloatingCard() {

  const scrollRef = useRef(null);
  const [t] = useTranslation("global")

  return (
      <div className='card_content'>
        <motion.div 
          className='card_content_box'
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 1 * 0.1 } }}
          viewport={{ root: scrollRef }}
        >
            <img            
              src={innovationImage}
              alt="Imagen"
            />
            <Typography sx={{ font: 'normal normal bold 20px/24px Roboto', color: '#7F00B2', 
            "@media (max-width: 768px)": {
              fontSize: "19px",
            "@media (max-width: 768px)": {
                fontSize: "17px",   
              }
            }}} className='card_content_box_text'>{t("Body.Innovative")}</Typography>
          </motion.div>

          <motion.div  
            className='card_content_box'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 2 * 0.1 } }}
            viewport={{ root: scrollRef }}>
            <img            
              src={automatizacionImage}
              alt="Imagen"
            />
            <Typography sx={{ color: '#0879B1', font: 'normal normal bold 20px/24px Roboto',
          "@media (max-width: 768px)": {
            fontSize: "18.5px",
          },"@media (max-width: 768px)": {
            fontSize: "17px",   
          }
          }} className='card_content_box_text'>{t("Body.Automatization")}</Typography>
          </motion.div>

          <motion.div 
            className='card_content_box'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 0.3, delay: 3 * 0.1 } }}
            viewport={{ root: scrollRef }}
                  >
            <img            
              src={uxImage}
              alt="Imagen"
            />
            <Typography sx={{ font: 'normal normal bold 20px/24px Roboto', color: '#7F00B2',
          "@media (max-width: 768px)": {
            fontSize: "19px",
            },
            "@media (max-width: 768px)": {
              fontSize: "17px",   
            }}} className='card_content_box_text'>{t("Body.Development")}</Typography>
          </motion.div>

          <motion.div 
            className='card_content_box'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 4 * 0.1 } }}
            viewport={{ root: scrollRef }}>
            <img            
              src={desarrolloImage}
              alt="Imagen"
            />
            <Typography sx={{ font: 'normal normal bold 20px/24px Roboto', color: '#0879B1',
          "@media (max-width: 768px)": {
            fontSize: "19px"
          },"@media (max-width: 768px)": {
            fontSize: "17px",   
          }}} className='card_content_box_text'>UX</Typography>
          </motion.div>

          <motion.div 
              className='card_content_box'
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: 5 * 0.1 } }}
              viewport={{ root: scrollRef }}>
            <img            
              src={simpleImage}
              alt="Imagen"
            />
            <Typography sx={{ font: 'normal normal bold 20px/24px Roboto', color: '#7F00B2',
          "@media (max-width: 768px)": {
            fontSize: "19px",
          },
          "@media (max-width: 768px)": {
            fontSize: "17px",   
          }}} className='card_content_box_text'>{t("Body.Simple")}</Typography>
          </motion.div>
      </div>
  );
}