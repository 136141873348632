import React, { useRef } from 'react'
import '../css/Cards.css'
import Map2 from '../assets/Desktop/Home/Image_mapamundi.png'
import icono1 from '../assets/Desktop/Home/icono_1.png'
import icono2 from '../assets/Desktop/Home/icono_2.png'
import forma3 from '../assets/Desktop/Home/Forma3_homepage.png'
import forma2 from '../assets/Desktop/Home/Forma2_Homepage.png'
import forma2Mobile from '../assets/Mobile/Home/Form2 home_mobile.png'
import imgForma3Mobile from '../assets/Mobile/Home/Form3 home_mobile.png'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const SimpleCard = () => {

  const scrollRef = useRef(null);
  const [t] = useTranslation("global")

  return (
    <motion.div
      className='divContainer'
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
      viewport={{ root: scrollRef }}
    >
      <img className='imgForma2' src={forma2} alt='forma2'></img>
      <img className='imgForma2Mbile' src={forma2Mobile} alt='forma2'></img>
      <article className="blog-post">
        <img className='imgForma3' src={forma3} alt='forma2'></img>
        <img className='imgForma3Mobile' src={imgForma3Mobile} alt='forma2'></img>
        <div className="blog-post__img">
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.1 }}
            viewport={{ root: scrollRef }}
            onClick={() => window.open('https://www.google.com/maps/place/Symbiotic/@9.9323642,-84.0974903,13.75z/data=!4m6!3m5!1s0x8fa0e3f50c44cb3f:0xf827043ecb0b51fc!8m2!3d9.928526!4d-84.0584735!16s%2Fg%2F11h4w9wr36?hl=es')} src={Map2} alt="" />
        </div>
        <div className="blog-post__info">
          <p>
            <strong>{t("Body.MapText")}</strong> {t("Body.MapText2")} <strong>{t("Body.MapText3")}</strong> {t("Body.MapText4")}
          </p>
          <ol className='blog-post__info_list'>
            <li><img src={icono1} style={{ width: '25px', height: '25px', marginRight: '8px' }} alt='forma2'></img>{t("Body.MapAsk1")}</li>
            <li><img src={icono2} style={{ width: '25px', height: '25px', marginRight: '8px' }} alt='forma2'></img>{t("Body.MapAsk2")}</li>
          </ol>
        </div>
      </article>
    </motion.div>
  )
}

export default SimpleCard