import React from 'react'
import logo from '../assets/Desktop/Footer/Logo_footer.png'
import line from '../assets/Desktop/Footer/line.png'
import line2 from '../assets/Mobile/Footer/Form1_mobile.png'
import line3 from '../assets/Mobile/Footer/Form3_mobile.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {

  const [t] = useTranslation("global")

  return (
    <Grid spacing={2} container justifyContent={'center'} alignItems={'center'}
      sx={{ background: '#E2CEF6' }}>
      <Grid xs={12} sm={3}>
        <img className='line' style={{ height: '230px', marginLeft: '3%', marginBottom: '-3px' }} src={line} alt='forma2'></img>
        <style>{`
              @media (max-width: 600px) {
                .line {
                  display: none;
                }
              }
            `}</style>
      </Grid>
      <Grid className='GridTextLogo' item xs={12} sm={3} textAlign="center" >

        <img className='logo' src={logo} alt="Logo" />
        <img className='line3' src={line3} alt='forma2'></img>
        <style>{`
              .logo {
                  width: 80%;
              }

              .line2 {
                display: none;
              }

              .line3 {
                display: none;
              }
              
              @media (max-width: 600px) {
                .logo {
                  width: 60%;
                }

                .line2 {
                  display: block;
                  margin-left: 70%;
                  margin-top: -15%;
                  width: 60px;
                }
               
                .line3 {
                  display: block;
                  margin-left: auto;
                  width: 90px;
                }

                
                .GridTextLogo {
                  height: 110px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: left;
                  margin-left: 15%;
                }

                .GridText {
                  text-align: left;
                  margin-left: 15%;
                }

                .typographyIcons {
                  justify-content: flex-start;
                }
              }
            `}</style>
      </Grid>
      <Grid className='GridText' item xs={12} sm={3} textAlign="center">
        <Typography variant="body1" sx={{ letterSpacing: '0px', fontSize: '1.1rem', cursor: 'pointer', '&:hover': { fontWeight: 'bold', color: "#333333" } }}>
          sales@oneautomata.com
        </Typography>
        <Typography className='typographyIcons' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {t("Footer.Social")}
          <div>
            <a href='https://www.linkedin.com/company/one-automata/'><LinkedInIcon sx={{ cursor: 'pointer', color: '#AE3ADB', marginLeft: 1, '&:hover': { color: "#7F00B2" } }} /></a>
            <FacebookIcon sx={{ cursor: 'pointer', color: '#AE3ADB', '&:hover': { color: "#7F00B2" } }} />
          </div>
        </Typography>
      </Grid>
      <Grid className='GridText' item xs={12} sm={3} textAlign="center" marginBottom={'10px'}>
        <Link to='/Contact'>
          <Button
            sx={{ borderRadius: 32, width: 150, textAlign: 'center', background: '#AE3ADB', '&:hover': { backgroundColor: '#7F00B2' } }} variant="contained">{t("Footer.ContactUs")}</Button>
        </Link>
      </Grid>
      <img className='line2' src={line2} alt='forma2'></img>
    </Grid>
  )
}

export default Footer