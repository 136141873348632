import React from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "../components/Contact";
import Home from '../components/Home';
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
  
  const location = useLocation();

  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path= {"/"} element={<Home/>}/>  
            <Route path= {"/Contact"} element={<Contact/>}/>
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes