import React, { useRef } from 'react'
import '../css/Cards.css'
import homePageImage from '../assets/Desktop/Home/Imagen_homepage.png'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const ContactCard = () => {

  const scrollRef = useRef(null);
  const [t] = useTranslation("global")

  return (
          <div className='card_container'>
            <motion.div 
            className='card_content_contact'
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
            viewport={{ root: scrollRef }}
            >
              <div>
                <p className='card_content_contact_title'>{t("Body.Working")}</p>
                <p className='card_content_contact_title2'>{t("Body.Working2")}</p>
              </div>
              <div className='card_content_contact_groupText'>
                <span className='card_content_contact_text'>{t("Body.WorkingText")} <strong>{t("Body.WorkingText2")}</strong> {t("Body.WorkingText3")}</span>
              </div>
              <p className='card_content_contact_text_question'><strong>{t("Body.WorkingQuestion")}</strong> <br></br> {t("Body.WorkingButton")}</p>
                <Link to='/Contact'>
                  <button>{t("Body.WorkingButton2")}</button>
                </Link>
            </motion.div>
              <motion.img 
              style={{zIndex: '2'}} 
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
              viewport={{ root: scrollRef }}
              src={homePageImage} 
              alt="placeholder image"/>
          </div>
  )
}

export default ContactCard
