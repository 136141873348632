import React, {useEffect, useState} from 'react'
import '../css/Carousel.css'
import dataImage from '../assets/DataImages'
import forma1 from '../assets/Desktop/Home/Header Home.png'
import lineImage from '../assets/Desktop/Home/Forma1_Homepage.png'
import { motion } from 'framer-motion';

const Carousel = () => {

  console.log(dataImage)

  // const [currentState, setCurrentState] = useState(0)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentState(currentState === dataImage.length - 1 ? 0 : currentState + 1)
  //   }, 5000)
  //   return () => clearInterval(interval)
  // })

  return (
           
    <div className="slideContainer">
        {/* <div className='image_carousel' style={{backgroundImage: `url(${dataImage[currentState].url})`}}></div> */}
        <motion.img 
        src={forma1} 
        className='image_carousel'
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
        ></motion.img>
        <div className='trasparent-background'></div>
         {/* <div className="description">
            <div>
                <h1>{dataImage[currentState].title}</h1>
                <p>{dataImage[currentState].body}</p>
            </div>
            <div className='carousel-boullt'>
                {dataImage.map((imageSlide, currentState) => (           
                    <span key={currentState} onClick={() => setCurrentState(currentState)}></span>   
                ))}
            </div>
        </div>  */}
        <motion.img 
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2 } }}
        src={lineImage} 
        alt="forma1" 
        className="forma1" />
    </div>
  )
}

export default Carousel