import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const ProjectsCard = () => {

  const scrollRef = useRef(null);
  const [t] = useTranslation("global")

  const projects = [{image: "https://cdn.vox-cdn.com/thumbor/JA2Fe7gAA000OLOicAC1QhFG_Fk=/1400x1050/filters:format(png)/cdn.vox-cdn.com/uploads/chorus_asset/file/9400503/prestotablet.png", title: t("Body.ProjectTitle"), description: t("Body.ProjectText")}, 
                  {image: "https://www.falabella.com.co/static/minisitios/guia_compras/gc-celulares/assets/img/heromob.jpg", title: t("Body.ProjectTitle2"), description: t("Body.ProjectText2")}, 
                  {image: "https://uploads-ssl.webflow.com/627bcc3621084c83da56b474/632340ee6786d7486710625d_pagar-con-c%C3%B3digo-qr.jpg", title: t("Body.ProjectTitle3"), description: t("Body.ProjectText3")}, 
                  {image: "https://media.gazetadopovo.com.br/2021/07/13130628/bigstock-a-young-caucasian-man-has-his-420091474-660x372.jpg", title: t("Body.ProjectTitle4"), description: t("Body.ProjectText4")}]

  return (
    <Card style={{ marginLeft: 'auto', marginRight: 'auto', boxShadow: 'none', marginBottom: '5rem', overflow: 'initial', 
    "@media (max-width: 1024px)": {
      marginTop: "25%",
    },
    "@media (max-width: 768px)": {
      marginTop: "12%",
    },
    "@media (max-width: 425px)": {
      marginTop: "-25%",
    }}}>
        <CardContent sx={{width: '100%', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'}}>
            {projects.map((image, index) =>
            <motion.div 
            style={{display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '1rem', marginBottom: '2rem', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 0.2, delay: index * 0.05 } }}
            viewport={{ root: scrollRef }}
            >
                <CardMedia
                    sx={{ margin: '0 auto', display: 'inline-block', width: '200px', borderRadius: '5px', boxShadow: '0px 5px 10px #00000029', backgroundColor: '#7F00B2', marginBottom: '10px'}}
                    component="img"
                    key={index}
                    height="160"
                    image={image.image}
                    alt="green iguana"
                />
                <Typography sx={{display: 'inline-block', font: 'normal normal bold 20px/37px Roboto', letterSpacing: '0px', color: '#7F00B2', opacity: '1', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)'}}>{image.title}</Typography>
                <Typography sx={{margin: '0 auto' ,maxWidth: 220 ,display: 'inline-block', font: 'normal normal 16px/20px Roboto', letterSpacing: '0px', color: '#333333', opacity: '1', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)'}}>{image.description}</Typography>
            </motion.div>
            )}
        </CardContent>
    </Card>
  )
}

export default ProjectsCard