const imageSlide = [
    {
        url: 'https://images.pexels.com/photos/6812431/pexels-photo-6812431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        title: 'Digitalizacion',
        body: 'Prometemos hacerlos estar por delante del resto y pertenecer a la nueva era digital.'
    },
    {
        url: 'https://images.pexels.com/photos/5054532/pexels-photo-5054532.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        title: 'Seguridad y Calidad',
        body: 'Somos la primer empresa del continente americano en contar con la certificación CPoC de PCI.'
    },
    {
        url: 'https://images.pexels.com/photos/8475145/pexels-photo-8475145.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
        title: 'Innovacion y Tecnologia',
        body: 'Empresa prionera en desarrollar e implementar Tap on Phone en Latinoamérica.'
    }
]

export default  imageSlide 