import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import logo2 from '../assets/Desktop/Header/Logo menu.png';
import  LanguageIcon  from '../assets/Desktop/Header/Icono_idiomas.png';
import { styled, alpha } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import LanguageIcon2 from '@mui/icons-material/Language';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 120,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
        padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#AE3ADB',
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function ResponsiveAppBar() {

  const [t , i18n] = useTranslation("global")
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [value, setValue] = useState('EN');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const pages = [t("Appbar.Home"), t("Appbar.Contact"), value];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ChooseLanguages = (languajes) => {
    console.log(languajes)
    if(languajes === "es"){
      //setLanguages(languajes)
      setValue("ES")
      i18n.changeLanguage(languajes)
    }else{
      //setLanguages(languajes)
      setValue("EN")
      i18n.changeLanguage(languajes)
    }
  }

  return (
    <AppBar position="static" sx={{background: '#7F00B2', height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Link to="/">

          {/* Normal Appbar */}

          <CardMedia
            component="img"
            image={logo2}
            alt="logo"
            sx={{
              width: '250px',
              height: '48px',
              objectFit: 'contain',
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          /></Link>
          
          {/* Responsive Appbar */}

          <CardMedia
            component="img"
            image={logo2}
            alt="logo"
            sx={{ marginRight: 'auto', width: '150px', objectFit: 'contain', height: '40px', mr: 2, display: { xs: 'flex', md: 'none' }}}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              sx={{ marginLeft: 'auto' }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                page === value ? <MenuItem key={page} onClick={()=> ChooseLanguages(value === 'EN' ? 'es' : 'en')}><LanguageIcon2 sx={{ opacity: 0.8, fontSize: '19px', marginRight: 0.5 }}/>{page}</MenuItem> :
                <Link to={page === t("Appbar.Home") ? '/' : '/Contact'}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography sx={{ color: '#333333' }} textAlign="center">{page}</Typography>
                   </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <ButtonGroup sx={{ marginLeft: 'auto'}}>
            {pages.map((page) => (
              page === value ? 
              <>
              <Button 
                color="inherit"
                sx={{ border: 'none', color: '#ffff', height: '37px', fontSize: '17px', fontWeight: 'bold', textTransform: 'none', marginTop: '5px'}}
                key={page}
                onClick={handleClick}><img src={LanguageIcon} style={{ width: '15px', height: '15px', marginRight: '3px'}}></img>{page}</Button> 
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem sx={{  height: 35 }} onClick={handleClose} disableRipple>
                      <RadioGroup
                        disableRipple
                        color="default"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={() => ChooseLanguages("en")}
                      >
                        <FormControlLabel value="EN" control={<Radio />} label={t("Appbar.English")} />
                      </RadioGroup>
                </MenuItem>
                <MenuItem sx={{ height: 35}} onClick={handleClose} disableRipple>
                      <RadioGroup
                        disableRipple
                        color="default"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={() => ChooseLanguages("es")}
                      >
                        <FormControlLabel value="ES" control={<Radio />} label={t("Appbar.Spanish")} />
                      </RadioGroup>
                </MenuItem>
                </StyledMenu>
                </>
              :
              <Link to={page === t("Appbar.Home") ? '/' : '/Contact'}>
              <Button
                color="inherit"
                sx={{ border: 'none', color: '#ffff', height: '37px', fontSize: '17px', fontWeight: 'bold', textTransform: 'none', marginTop: '5px'}}
                key={page}
                onClick={handleCloseNavMenu}
              >
                {page}
              </Button>
              </Link>
            ))}
            </ButtonGroup>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;